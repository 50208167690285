<template>
  <div class="form-counseling">
    <a-form-model ref="formRef" :model="newData" :rules="rules">
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="Student Name" prop="id_kelas_tahun_ajaran">
            <a-tree-select
              show-search
              class="w-100"
              style="max-width: 500px;"
              v-model="newData.id_kelas_tahun_ajaran"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="Select Student Name"
              allow-clear
              size="large"
            >
              <a-tree-select-node v-for="kelas in (fieldsForm.dataKelas || [])" :value="kelas.id" :key="'kelas-' + kelas.id" :selectable="false" :title="`${kelas.tingkat} ${kelas.simbol}`">
                <a-tree-select-node v-for="murid in (kelas.dataMurid || [])" :key="murid.id_kelas_tahun_ajaran" :value="murid.id_kelas_tahun_ajaran" :title="murid.nama" />
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="Privacy Status">
            <a-radio-group
              style="max-width: 500px;"
              class="w-100"
              size="large"
              button-style="solid"
              :value="newData.type"
              @change="(e) => handleChange(e.target.value, 'type')"
            >
              <a-radio-button class="w-50 text-center" value="private">
                Private
              </a-radio-button>
              <a-radio-button class="w-50 text-center" value="public">
                Public
              </a-radio-button>
            </a-radio-group>
            <br />
            <small>
              <span class="text-danger">*</span>
              <template v-if="newData.type === 'private'">
                This report can only be read by all of counseling teacher
              </template>
              <template v-else>
                This report can be read by subject teacher, homeroom teacher, and school executives
              </template>
            </small>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="Title" prop="title">
            <a-input
              size="large"
              style="max-width: 500px;"
              class="w-100"
              :value="newData.title"
              @change="(e) => handleChange(e.target.value, 'title')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="Counseling Date" prop="counseling_date">
            <a-date-picker
              v-model="newData.counseling_date"
              style="max-width: 500px;"
              size="large"
              placeholder="Select Counseling Date"
              class="w-100"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="Counseling Type" prop="id_kategori_bk">
            <a-select style="max-width: 500px;" show-search @change="(e) => handleChange(e, 'id_kategori_bk')" placeholder="Select Counseling Type" size="large" :value="newData.id_kategori_bk">
              <a-select-option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="Counseling Status">
            <a-radio-group
              style="max-width: 500px;"
              class="w-100"
              size="large"
              button-style="solid"
              :value="newData.status"
              @change="(e) => handleChange(e.target.value, 'status')"
            >
              <a-radio-button class="w-50 text-center" value="on_going">
                Ongoing
              </a-radio-button>
              <a-radio-button class="w-50 text-center" value="closed">
                Closed
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="Concern Indicator">
        <div class="px-3 mx-3">
          <a-slider style="max-width: 1000px;" :marks="marks" :min="1" :max="5" v-model="newData.concern_indicator"/>
        </div>
      </a-form-model-item>
      <a-form-model-item prop="concern">
        <label slot="label">Concerns: <small class="text-warning">(leave blank if there is nothing you want to add)</small></label>
        <ckeditor
          :value="newData.concerns"
          @input="e => handleChange(e, 'concerns')"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
      </a-form-model-item>
      <a-form-model-item prop="comment" :colon="false">
        <label slot="label">Analysis: <small class="text-warning">(leave blank if there is nothing you want to add)</small></label>
        <ckeditor
          :value="newData.analysis"
          @input="e => handleChange(e, 'analysis')"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
      </a-form-model-item>
      <a-form-model-item prop="solution">
        <label slot="label">Solution: <small class="text-warning">(leave blank if there is nothing you want to add)</small></label>
        <ckeditor
          :value="newData.solution"
          @input="e => handleChange(e, 'solution')"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { BKMarks } from '@/helpers/general'

const rules = {
  title: [{ required: true, message: 'title is required!', trigger: 'blur' }],
  counseling_date: [{ required: true, message: 'counseling date is required!', trigger: 'blur' }],
  id_kelas_tahun_ajaran: [{ required: true, message: 'student is required!', trigger: 'blur' }],
  id_kategori_bk: [{ required: true, message: 'type is required!', trigger: 'blur' }],
}

export default {
  props: {
    newData: {
      type: Object,
      required: true,
    },
    isSubmit: {
      type: Boolean,
    },
    categories: {
      type: Array,
    },
    fieldsForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules,
      marks: BKMarks,
    }
  },
  computed: {
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      const config = this.$store.state.ckEditor.editorConfig
      const items = config.toolbar.items.filter(item => item !== 'MathType')
      return {
        ...config,
        toolbar: {
          items: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            ...items,
          ],
          shouldNotGroupWhenFull: true,
        },
      }
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  watch: {
    isSubmit(newVal) {
      if (newVal) {
        this.$refs.formRef.validate(valid => {
          this.$emit('handle-validate', valid)
        })
      }
    },
  },
  mounted() {
    console.log(this.newData)
  },
}
</script>

<style lang="scss">
.form-counseling {
  .ck-editor__editable {
      min-height: 300px;
  }
}
</style>
